<template>
	<div v-loading="loading">
		<div class="navCommon">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: 'customerlist' }">客户列表</el-breadcrumb-item>
				<el-breadcrumb-item>客户详情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="userinfo">
			<el-descriptions class="margin-top" :column="3" border>
				<template slot="title">
					<el-button type="primary" size="small">客户信息</el-button>
				</template>
				<el-descriptions-item>
					<template slot="label">
						<i class="el-icon-user"></i>
						用户姓名
					</template>
					{{detailsdata.useproCustomerName}}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">
						<i class="el-icon-mobile-phone"></i>
						手机号
					</template>
					{{detailsdata.useproCustomerTel}}
				</el-descriptions-item>
				<el-descriptions-item contentClassName='sferw'>
					<template slot="label">
						<i class="el-icon-tickets"></i>
						备注
					</template>
					{{detailsdata.useproDescribe}}
				</el-descriptions-item>
			</el-descriptions>
		</div>
		<div>
			<el-descriptions border colon>
				<template slot="title">
					<el-button type="primary" size="small">需求信息</el-button>
				</template>
				<el-descriptions-item label="实训室人数">{{detailsdata.useproCustomerTel}}</el-descriptions-item>
				<el-descriptions-item label="学校名称">{{detailsdata.useproSchoolName}}</el-descriptions-item>
				<el-descriptions-item label="实训室面积">{{detailsdata.useproShixunArea}}</el-descriptions-item>
				<el-descriptions-item v-if="detailsdata.useproShixunContent" label="实训室说明">
					{{detailsdata.useproShixunContent}}
				</el-descriptions-item>
				<el-descriptions-item label="顶面装修规格">
					{{detailsdata.Styletop}}
				</el-descriptions-item>
				<el-descriptions-item label="地面装修规格">
					{{detailsdata.Stylebot}}
				</el-descriptions-item>
				<el-descriptions-item label="实训室类型" v-if="detailsdata.shixunType">{{detailsdata.shixunType.shixunName}}
				</el-descriptions-item>
				<template v-if="detailsdata.shixunType&&detailsdata.shixunType.trainSeconds">
					<el-descriptions-item :label="item.nextName"
						v-for="(item,index) in detailsdata.shixunType.trainSeconds">{{item.planName}}
					</el-descriptions-item>
				</template>
				<el-descriptions-item label="总价">{{detailsdata.useproPrice}}元
				</el-descriptions-item>
				<el-descriptions-item label="方案下载">
					<el-button type="primary" icon="el-icon-download" circle @click="downloads"></el-button>
				</el-descriptions-item>
			</el-descriptions>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		components: {},
		data() {
			return {
				loading: false,
				detailsdata: ''
			}
		},
		created() {
			if (this.$route.query.id) {
				this.demandDetails(this.$route.query.id)
			}
		},
		mounted() {

		},
		methods: {
			async downloads() {
				this.loading = true
				let exurl = ""
				await this.$post('downloadUserDemand', {
					id: this.$route.query.id
				}).then((res) => {
					console.log(res)
					exurl = res
						this.loading = false
					this.Urlstrues(res).then(result => {
						if (result) {
							window.location.href = exurl
						} else {
							this.$message({
								message: '下载地址有误请稍后再试',
								type: 'warning'
							});
						}
					})
				})
			},
			async Urlstrues(url) {
				let data = ""
				await axios.get(url)
					.then(response => {
						if (response) {
							data = true
						} else {
							data = false
						}
					}, err => {
						data = false
					})
				return data
			},
			demandDetails(id) {
				this.loading = true
				this.$post('queryUserDemandDetails', {
					planId: id
				}).then((res) => {
					res.trimStyle.bot == 0 ? res.Stylebot = '无' : res.trimStyle.bot == 1 ? res.Stylebot = '简装' :
						res.trimStyle.bot == 2 ? res.Stylebot = '中装' : res.Stylebot = '精装'
					res.trimStyle.top == 0 ? res.Styletop = '无' : res.trimStyle.top == 1 ? res.Styletop = '简装' :
						res.trimStyle.top == 2 ? res.Styletop = '中装' : res.Styletop = '精装'
					this.detailsdata = res
					this.loading = false
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.userinfo {
		margin: 40px 0;
		// width: 60%;
	}
</style>
<style>
	.sferw {
		width: 500px;
	}
</style>
